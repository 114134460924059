.container {
  background-color: #333;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
}

textarea {
  font-size: 18px;
  padding: 10px 0 0 20px;
  box-sizing: border-box;
  font-family: monospace;
  background-color: #5b0888;
  color: white;
}

button {
  padding: 15px 30px;
  margin-bottom: 15px;
  font-size: 20px;
  background-color: greenyellow;
  color: blue;
  font-weight: bolder;
  font-family: monospace;
  border: none;
}

button:hover {
  color: orangered;
  background-color: aqua;
}

button:active {
  background-color: green;
  color: #fff;
}

.btnGroup {
  display: flex;
  flex-direction: column;
}

select {
  /* padding: 15px 30px; */
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bolder;
  font-family: monospace;
  text-align: center;
  color: red;
}

select > option {
  /* padding: 15px 30px; */
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bolder;
  font-family: monospace;
  text-align: center;
  border-bottom: 1px solid black;
  color: blue;
}

input {
  margin-bottom: 15px;
}

.messageText {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 40px;

  color: whitesmoke;
  font-size: 18px;
  text-align: center;
}