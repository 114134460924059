.container_get_cmt {
  background-color: #333;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  height: 100vh;
}

.container_get_cmt > textarea {
  font-size: 18px;
  padding: 10px 0 0 20px;
  /* margin: 26px 0; */
  box-sizing: border-box;
  font-family: monospace;
}

button {
  padding: 15px 30px;
  margin-bottom: 15px;
  font-size: 20px;
  background-color: greenyellow;
  color: blue;
  font-weight: bolder;
  font-family: monospace;
  border: none;
  text-decoration: none;
}

button:hover {
  color: orangered;
  background-color: aqua;
}

button:active {
  background-color: green;
  color: #fff;
}

.btnGroup {
  display: flex;
  flex-direction: column;
}

.cmt {
  color: white;
  margin: 15px;
  font-size: 18px;
  font-family: monospace;
}

.cmt:hover {
  /* opacity: 0.8; */
  cursor: pointer;
  color: orange;
}
