.container_transfer_money_menu{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh; /* Đảm bảo chiều cao bằng toàn màn hình */
    margin: 0; /* Loại bỏ khoảng cách mặc định */
    margin-top: 10%;
}

.container_transfer_money{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    width: 40%;
}

.form-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }
  
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    /* margin-bottom: 5px; */
    margin-right: 10px;
    color: #ffffff; /* Màu chữ trắng để nổi bật trên nền xanh */
  }
  
  input {
    padding: 10px;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 5px;
    /* width: 100%; Chiều rộng đầy đủ */
    box-sizing: border-box; /* Đảm bảo padding không làm thay đổi chiều rộng */
    margin: 0;
  }  