.container_get_text_translate {
  background-color: #333;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  height: 100vh;
}

.container_get_text_translate > textarea {
  font-size: 18px;
  padding: 10px 0 0 20px;
  /* margin: 26px 0; */
  box-sizing: border-box;
  font-family: monospace;
}

button {
  padding: 15px 30px;
  margin-bottom: 15px;
  font-size: 20px;
  background-color: greenyellow;
  color: blue;
  font-weight: bolder;
  font-family: monospace;
  border: none;
  text-decoration: none;
}

button:hover {
  color: orangered;
  background-color: aqua;
}

button:active {
  background-color: green;
  color: #fff;
}

.btnGroup {
  display: flex;
  flex-direction: column;
}

.text_translate {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* background-color: red; */
  margin-bottom: 10px;
}

.text_translate > .name_language {
  margin: 0;
  margin-right: 20px;
  color: yellow;
  font-size: 18px;
  font-family: monospace;
  font-weight: bolder;
}

.cmt_text {
  color: white;
  margin: 0px;
  font-size: 18px;
}

.cmt_text:hover {
  cursor: pointer;
  color: aqua;
}

@media only screen and (max-width: 740px) {
  .container_get_text_translate {
    flex-wrap: wrap;
    background-color: red;
  }
}
