.container {
  background-color: #292a2d;
}

.tts_us {
  background-color: #414257 ;
  color: white;
  font-size: 18px;
  padding: 7px 5px 0 12px;
  box-sizing: border-box;
  font-family: monospace;
}

.text__note {
  background-color: #5b0888;
}

.btnGroupUS {
  height: 95vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.btnGroupUS > button {
  padding: 12px 0px;
  margin-bottom: 10px;
  font-size: 20px;
  background-color: greenyellow;
  color: blue;
  font-weight: bolder;
  font-family: monospace;
  border: none;
  text-decoration: none;
}

.btnGroupUS > button:hover {
  color: orangered;
  background-color: aqua;
}

.btnGroupUS > button:active {
  background-color: green;
  color: #fff;
}

.btnGroupUS>input{
  font-size: 16px;
  font-family: monospace;
  padding: 6px;
  margin-bottom: 15px;
}

.messageText {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 12px 0 0 0;

  color: whitesmoke;
  font-size: 18px;
  text-align: center;
}

@media only screen and (max-width: 740px) {
  .container {
    height: auto;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .btnGroupUS {
    height: auto;
  }

  textarea {
    margin-top: 2%;
    margin-bottom: 3%;
    padding: 10px;
    width: 95%;
    height: 20vh;
  }

  .btnGroupUS > input {
    font-size: 16px;
    margin-bottom: 10px;
  }

  audio {
    margin-bottom: 6%;
  }
}
