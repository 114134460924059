body {
  background-color: #3f51b5;
}

.container_menu{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Đảm bảo chiều cao bằng toàn màn hình */
  margin: 0; /* Loại bỏ khoảng cách mặc định */
  font-size: 20px;
}

.container_home {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 80%;
  height: 710px;
  margin: 0;
  padding: 0;
}

.btn_getCmt {
  align-self: center;
  margin: 0;
  margin-right: 20px;
  margin-bottom: 40px;
}

.btn_getCmt {
  display: inline-block; /* hiển thị như một khối nhỏ */
  padding: 12px 20px; /* độ lớn của button */
  background-color: #4caf50; /* màu nền */
  color: white; /* màu chữ */
  text-align: center; /* căn giữa nội dung */
  cursor: pointer; /* thay đổi hình dạng chuột khi di chuột qua */
  border-radius: 5px; /* bo tròn viền */
}

.btn_getCmt:hover {
  background-color: #3e8e41; /* màu nền khi hover */
  color: yellow;
}

@media only screen and (max-width: 740px) {
  .container_home {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    height: 100vh;
    margin: 0;
    padding: 0;
  }

  .btn_getCmt {
    margin: 5px 0;
    margin-right: 10px;
  }
}
